/**
 * AaronBentley Metadata Hook
 *
 * @export useSiteMetadata
 */
import { graphql, useStaticQuery } from 'gatsby'

export const useSiteMetadata = () => {
    const { site:{ siteMetadata = {} } } = useStaticQuery(
        graphql`
            query SITE_METADATA {
                site {
                    siteMetadata {
                        title
                        siteUrl
                        description
                        author
                        social {
                            github
                            twitter
                            instagram
                            facebook
                        }
                    }
                }
            }
        `
    )

    return siteMetadata
}