/** @jsx jsx */
/**
 * Aaron Bentley Social Links
 *
 * @export SocialLinks
 */
import {
    faFacebookF,
    faGithub,
    faInstagram,
    faTwitter
} from '@fortawesome/free-brands-svg-icons'
import { faCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Flex, jsx } from 'theme-ui'
import Link from '../link/link'

const SocialLinks = () => {
    return (
        <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Link
                to={process.env.GATSBY_GITHUB_URL}
                sx={{
                    px: 3
                }}>
                <Box
                    as='span'
                    className='fa-layers fa-fw'
                    sx={{
                        fontSize: [6, 5, 7],
                        '&:hover': {
                            '& > svg[data-icon="circle"]': {
                                color: 'highlight'
                            }
                        }
                    }}>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faCircle}
                        sx={{
                            color: 'primary',
                            transition: ({ durations }) => `color ${durations[1]} ease`,
                        }}
                        transform='grow-4' />
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faGithub}
                        sx={{ color: 'white' }}
                        transform='shrink-6' />
                </Box>
            </Link>
            <Link
                to={process.env.GATSBY_TWITTER_URL}
                sx={{
                    px: 3
                }}>
                <Box
                    as='span'
                    className='fa-layers fa-fw'
                    sx={{
                        fontSize: [6, 5, 7],
                        '&:hover': {
                            '& > svg[data-icon="circle"]': {
                                color: 'highlight'
                            }
                        }
                    }}>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faCircle}
                        sx={{
                            color: 'primary',
                            transition: ({ durations }) => `color ${durations[1]} ease`,
                        }}
                        transform='grow-4' />
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faTwitter}
                        sx={{ color: 'white' }}
                        transform='shrink-6' />
                </Box>
            </Link>
            <Link
                to={process.env.GATSBY_INSTAGRAM_URL}
                sx={{
                    px: 3
                }}>
                <Box
                    as='span'
                    className='fa-layers fa-fw'
                    sx={{
                        fontSize: [6, 5, 7],
                        '&:hover': {
                            '& > svg[data-icon="circle"]': {
                                color: 'highlight'
                            }
                        }
                    }}>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faCircle}
                        sx={{
                            color: 'primary',
                            transition: ({ durations }) => `color ${durations[1]} ease`,
                        }}
                        transform='grow-4' />
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faInstagram}
                        sx={{ color: 'white' }}
                        transform='shrink-6' />
                </Box>
            </Link>
            <Link
                to={process.env.GATSBY_FACEBOOK_URL}
                sx={{
                    px: 3
                }}>
                <Box
                    as='span'
                    className='fa-layers fa-fw'
                    sx={{
                        fontSize: [6, 5, 7],
                        '&:hover': {
                            '& > svg[data-icon="circle"]': {
                                color: 'highlight'
                            }
                        }
                    }}>
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faCircle}
                        sx={{
                            color: 'primary',
                            transition: ({ durations }) => `color ${durations[1]} ease`,
                        }}
                        transform='grow-4' />
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faFacebookF}
                        sx={{ color: 'white' }}
                        transform='shrink-6' />
                </Box>
            </Link>
        </Flex>
    )
}

export default SocialLinks
