import React from 'react'
import { Box, Heading } from 'theme-ui'
import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import SocialLinks from '../components/social-links/social-links'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const IndexPage = () => {
    const { title = '', description = '' } = useSiteMetadata()
    return (
        <Layout>
            <SEO title={title} description={description} />
            <Box sx={{ textAlign: 'center' }}>
                <Heading as='h1' sx={{ fontSize: [7, 8, 9] }}>
                    {title}
                </Heading>
                <Heading
                    as='h2'
                    sx={{
                        variant: 'text.heading',
                        background: ({ colors }) =>
                            `linear-gradient(0deg,${colors.cool},${colors.highlight})`,
                        color: 'cool',
                        fontSize: [5, 6, 7]
                    }}>
                    {description}
                </Heading>
                <Box
                    sx={{
                        my: 5
                    }}>
                    <SocialLinks />
                </Box>
            </Box>
        </Layout>
    )
}

export default IndexPage
